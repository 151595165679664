var tableColumns = [{
  title: "记录ID",
  dataIndex: "id",
  key: "id",
  width: 140
}, {
  title: "广告主昵称",
  dataIndex: "advertisername",
  key: "advertisername",
  width: 120
}, {
  title: "创作者昵称",
  dataIndex: "creatorname",
  key: "creatorname",
  width: 100
}, {
  title: "整体推广能力",
  dataIndex: "promotionability",
  key: "promotionability",
  width: 100,
  scopedSlots: {
    customRender: "promotionability"
  }
}, {
  title: "投稿质量",
  dataIndex: "contentquality",
  key: "contentquality",
  width: 180
}, {
  title: "转化能力",
  dataIndex: "conversionability",
  key: "conversionability",
  width: 180
}, {
  title: "评分时间",
  dataIndex: "evaluatetime",
  key: "evaluatetime",
  width: 120,
  scopedSlots: {
    customRender: "evaluatetime"
  }
}];
export { tableColumns };